import { useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

const toTitleCase = (str: string) => {
    return str.replace(
        /\w\S*/g,
        function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

const getShortSem = (sem: string) => {
    const sem_split = sem.split(" ");
    if (sem_split[0] === "Fall") {
        sem_split[0] = "Fall";
    } else if (sem_split[0] === "Spring") {
        sem_split[0] = "Spr";
    } else if (sem_split[0] === "Summer") {
        sem_split[0] = "Sum";
    }
    return sem_split.join(" ");
}

const getProfName = (prof: string) => toTitleCase(prof.split(',')[0]);

const hasProperties = (dict, props: string[]) => {
    return props.every((prop) => dict.hasOwnProperty(prop));
};

const sharedState = {};
let updateTimeout: number | undefined;

const clearStateParams = () => {
    for (var prop in sharedState) {
        if (sharedState.hasOwnProperty(prop)) {
            delete sharedState[prop];
        }
    }
};

function useStateParams<T>(
    initialState: T,
    paramsName: string,
    serialize: (state: T) => string,
    deserialize: (state: string) => T
  ): [T, (state: T) => void] {
    const [searchParams, setSearchParams] = useSearchParams();
    const existingValue = searchParams.get(paramsName);
    
    // const initialValue = existingValue ? deserialize(existingValue) : initialState;
    const [state, setState] = useState<T>(
      existingValue ? deserialize(existingValue) : initialState
    );

    // if (initialValue !== null || initialValue !== "") sharedState[paramsName] = initialValue;
        
    useEffect(() => {
    //   console.log(`${paramsName}: ${existingValue}`);
    //   console.log(sharedState);
      // Updates state when user navigates backwards or forwards in browser history
      if (existingValue && deserialize(existingValue) !== state) {
        setState(deserialize(existingValue));
      }
      if (existingValue) {
        sharedState[paramsName] = existingValue;
      }
      if (!existingValue) {
        setState(initialState);
      }
    //   console.log(`Search->State: ${paramsName}: ${existingValue}`);
    }, [existingValue]);

    useEffect(() => {
        if (state === null || (state !== null && Array.isArray(state) && state.length === 0)) {
            if (sharedState.hasOwnProperty(paramsName)) delete sharedState[paramsName];
            // return;
        } else {
            sharedState[paramsName] = serialize(state);     
        }

        if (!existingValue || existingValue !== serialize(state))
        {
            if (hasProperties(sharedState, ['query', 'disp', 'agg', 'multi'])) {
                if (updateTimeout) {
                    clearTimeout(updateTimeout);
                }
                updateTimeout = setTimeout(() => {
                    const newSearchParams = new URLSearchParams();
                    Object.keys(sharedState).forEach(key => {
                        newSearchParams.set(key, sharedState[key]);
                    });
                    setSearchParams(newSearchParams);
                    }, 250); // Adjust the timeout duration as needed
    
                return () => clearTimeout(updateTimeout);
            }
        }
        // console.log(`State->Search (in dict): ${paramsName}: ${serialize(state)}`);
    }, [state]);
    
    return [state, setState];
}  

export { toTitleCase, getShortSem, getProfName, useStateParams, clearStateParams };
  