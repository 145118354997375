import React, { useState } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { House, InfoCircle, Moon, Sun } from 'react-bootstrap-icons';
import { InfoModal } from './Modals'

const UTGradesNavbar = ({ hasSearchResults, theme, toggleTheme }) => {
    const [infoModalShow, setInfoModalShow] = useState(false);

    return (
        <>
            <Navbar variant="light">
                <Container fluid>
                    <Nav className="me-auto">
                        <Nav.Link href="/" className="burnt-orange-hover">
                            <House className={`${hasSearchResults && 'me-2'}`} aria-label="Home"/>
                            {hasSearchResults && <span className="" style={{ verticalAlign: '-0.19em', fontWeight: 'bold', textTransform: 'uppercase' }} aria-label='UT Grades Plus'>UT Grades+</span>}
                        </Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link href="#" onClick={() => setInfoModalShow(true)} className="blue-icon burnt-orange-hover" aria-label="Info"><InfoCircle /></Nav.Link>
                        <Nav.Link href="#" onClick={toggleTheme} className="burnt-orange-hover" aria-label="Toggle Light Dark Mode">{theme === 'light' ? <Moon /> : <Sun />}</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>

            <InfoModal
                show={infoModalShow}
                onHide={() => setInfoModalShow(false)}
            />
        </>
    );
};

export default UTGradesNavbar;
