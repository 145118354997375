import React, { useEffect, useState, useRef } from 'react';
import { Form, FormControl, Button } from 'react-bootstrap';
import { Search, QuestionLg } from 'react-bootstrap-icons';
import { SearchInfoModal } from './Modals'

const exampleSearches = [
  "ECE 312 Gligoric Spring 2022",
  "ECE 360C Fall 2022 Soloveichik",
  "PSY 420M Alexander Fall 2022",
  // "Spring 2023 ECO 330T Michael Brandl"
]

const SearchBar = ({ hasResults, onSearch, searchQuery, setSearchQuery }) => {
  const randPlaceholder = () => {
    return exampleSearches[Math.floor(Math.random() * 99) % exampleSearches.length];
  }

  const inputRef = useRef(null); // Create a ref for the input field

  const [localQuery, setLocalQuery] = useState('');
  const [searchPlaceholder, setSearchPlaceholder] = useState(`Search; e.g. ${randPlaceholder()}`);
  const [searchInfoModalShow, setSearchInfoModalShow] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSearchPlaceholder(`Search; e.g. ${randPlaceholder()}`);
    setSearchQuery(localQuery);

    if (inputRef.current) inputRef.current.blur();
    // onSearch(localQuery);
  };

  useEffect(() => {
    setLocalQuery(searchQuery ? searchQuery : "");
  }, [searchQuery]);

  return (
    <Form onSubmit={handleSubmit} className="d-flex justify-content-center">
        <FormControl
          type="search"
          placeholder={searchPlaceholder}
          className={`me-2 minimal-search-bar ${!hasResults ? 'searchbar-cover' : 'searchbar-full'}`}
          aria-label="Search Bar"
          value={localQuery}
          onChange={(e) => setLocalQuery(e.target.value)}
          ref={inputRef}
        />
        <Button variant="outline-success" type="submit" className="me-2" aria-label="Search Icon"><Search /></Button>
        <Button variant="outline-primary" onClick={() => setSearchInfoModalShow(true)} aria-label="More Info"><QuestionLg /></Button>

        <SearchInfoModal
          show={searchInfoModalShow}
          onHide={() => setSearchInfoModalShow(false)}
        />
    </Form>
  );
};

export default SearchBar;