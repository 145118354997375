import React from 'react';
import { Card, Table, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Switch from "react-switch";
import { Person } from 'react-bootstrap-icons';
import { getProfName, getShortSem, toTitleCase } from "./Utilities.ts";

const entriesPerPage = 100;

const TableCard = ({ data, selectedCourses, toggleCourseSelection, addCourse, removeCourse, saveMode, setSaveMode }) => {
  // const [currentPage, setCurrentPage] = useState(1);
  // const numPages = Math.floor(data.length / entriesPerPage) + (data.length % entriesPerPage === 0 ? 0 : 1);

  const sumGrades = (item) => {
    return ['A', 'A_minus', 'A_plus', 'B', 'B_minus', 'B_plus', 'C', 'C_minus', 'C_plus', 'D', 'D_minus', 'D_plus', 'F', 'Other']
      .reduce((acc, grade) => acc + item[grade], 0);
  };

  const getPageData = () => {
    // const start = (currentPage - 1) * entriesPerPage;
    // return data.slice(start, start + entriesPerPage);
    return data;
  };

  const dummyOnclick = () => {
    return;
  }

  return (
    <Card className="shadow mb-2 the-table-card">
      <Card.Body className="pb-2">
        <label className={`mx-2 ${saveMode ? '' : 'mb-2'}`}>
          <div style={{verticalAlign: '-0.1em', display: 'inline'}}>
            <Switch
              onChange={() => {
                setSaveMode(!saveMode);
                selectedCourses.forEach((item_id) => removeCourse(item_id));
                const firstCourse = data.find((item) => selectedCourses.includes(item.internal_id));
                if (firstCourse) toggleCourseSelection(firstCourse.internal_id);
              }}
              checked={saveMode}
              height={12}
              width={30}
              handleDiameter={8}
              uncheckedIcon={false}
              checkedIcon={false}
            />     
          </div>     
          <span className={"ms-2"}>Multi-Select</span>
        </label>

        {
          saveMode &&
          (
            <div className="mb-1">
              <span className="mx-2 emph-on-hover" onClick={() => data.forEach(item => {
                addCourse(item.internal_id);
              })}><u>Select All</u></span>
              <span className="mx-2 emph-on-hover" onClick={() => data.forEach(item => {
                removeCourse(item.internal_id);
              })}><u>Deselect All</u></span>          
            </div>
          )
        }
        
        <Container fluid style={{ overflowY: 'auto' }} className="gx-0 tablecard-table">
          <Table striped bordered hover className="mb-0">
            <tbody>
              {getPageData().map((item, index) => (
                <tr key={index} style={{ cursor: 'pointer' }} onClick={() => toggleCourseSelection(item.internal_id)}>
                  <td style={{ borderRight: 'none', verticalAlign: 'middle', textAlign: 'left', padding: '0.6rem 0.75rem 0.5rem', lineHeight: '100%' }}>
                    { saveMode && 
                      <>
                        <OverlayTrigger
                          key={"top"}
                          placement={"top"}
                          overlay={
                            <Tooltip>
                              <span style={{ fontVariant: 'small-caps' }}>{toTitleCase(item.course_name)}</span>
                            </Tooltip>
                          }
                        > 
                          <span>
                            <span className='fw-bold' style={{ textDecoration: 'underline' }}>{item.dept}&nbsp;{item.course_nbr}</span>
                            <span> w/&nbsp;<span className='text-capitalize'>{getProfName(item.prof)}</span></span>
                          </span>
                        </OverlayTrigger>
                      </>
                    } 

                    {
                      !saveMode &&
                      <>
                        <span className='fw-bold'>{item.dept}&nbsp;{item.course_nbr}</span>
                        <span> w/&nbsp;<span className='text-capitalize'>{getProfName(item.prof)}</span></span>
                      </>
                    }

                    <br />
                    <span>
                      {getShortSem(item.sem).replace(/ /g, '\u00a0')} - {sumGrades(item)}&nbsp;<Person style={{verticalAlign: '-0.186em'}}/>
                    </span>
                  </td>
                  <td style={{ width: '1%', borderLeft: 'none', verticalAlign: 'middle', textAlign: 'center', paddingRight: '0.75rem'}}>
                    <input
                      type={`${saveMode === true ? 'checkbox' : 'radio'}`}
                      checked={selectedCourses.includes(item.internal_id)}
                      onChange={dummyOnclick}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
        
        <div style={{ lineHeight: '1em' }} className="mb-2 mt-3">
          {data.length < 100 ? 
            `${data.length === 0 ? 'No' : data.length} result${data.length === 1 ? '' : 's'} found.` :
            "First 100 results shown. Try a more specific query?"
          }
        </div>
      </Card.Body>
    </Card>
  );
};

export default TableCard;