import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import Switch from "react-switch";
import { Bar, } from 'react-chartjs-2';
import { Rainbow40 } from './Colors.js'
import { Chart, defaults, registerables } from 'chart.js';
import { getProfName, toTitleCase } from "./Utilities.ts";
Chart.register(...registerables);

const LABELS_TO_JSON_LABELS = {
  'A+': 'A_plus',
  'A-': 'A_minus',
  'B+': 'B_plus',
  'B-': 'B_minus',
  'C+': 'C_plus',
  'C-': 'C_minus',
  'D+': 'D_plus',
  'D-': 'D_minus'
}

const NUM_LABELS = 14;
let theSingleItem = null;

const getYearFromSemester = (sem) => {
  return sem.split(" ")[1];
};

const getSeasonNumFromSemester = (sem) => {
  if (sem.includes("Fall")) return "9";
  if (sem.includes("Spring")) return "2";
  if (sem.includes("Summer")) return "6";
  return "";
}

const GraphCard = ({ data, selectedCourses, displayMode, aggregateMode, saveMode, setDisplayMode, setAggregateMode, windowSize, theme }) => {
  const hasCourses = (selectedCourses.length !== 0);
  const windowWidth = windowSize[0];
  const windowHeight = windowSize[1];

  // Graphcard should (at least) match Tablecard's height
  useEffect(() => {
      const tablecard = document.querySelector('.the-table-card');
      const graphcard = document.querySelector('.the-graph-card');
      
      graphcard.style.minHeight = `${tablecard.clientHeight}px`;    
  }, [hasCourses, windowWidth, windowHeight, saveMode]);

  // useEffect(() => {
  //   // console.log(selectedCourses);
  // }, [...selectedCourses]);

  const filteredData = data.filter(item => selectedCourses.includes(item.internal_id));
  const labels = filteredData.some(item => item.dept === 'LAW') ? 
                 ['A+', 'A', 'A-', 'B+', 'B', 'B-', 'C+', 'C', 'C-', 'D+', 'D', 'D-', 'F', 'Other'] : 
                 ['A', 'A-', 'B+', 'B', 'B-', 'C+', 'C', 'C-', 'D+', 'D', 'D-', 'F', 'Other'];
  
  const isOnlyOneCourse = (filteredData.length === 1);
  const hasAPlus = (labels.length === NUM_LABELS);

  const prepareGraphData = () => {
    if (isOnlyOneCourse) {
      theSingleItem = filteredData.find((item) => item.internal_id === selectedCourses[0]);
    }

    // previously here: set the aggregate mode based on course numbers. mmoved to Results.js

    const columnRainbowColors = Rainbow40.slice(0, hasAPlus ? NUM_LABELS + 1 : NUM_LABELS).reverse();

    // DataSET Return (implements functionality for both modes)
    const datasets = filteredData.map((item, index) => {
      const dataPoints = labels.map(label => item[(LABELS_TO_JSON_LABELS.hasOwnProperty(label) ? LABELS_TO_JSON_LABELS[label] : label)] || 0);
      const total = dataPoints.reduce((sum, val) => sum + val, 0);

      return {
        label: (aggregateMode || isOnlyOneCourse) ? '' : `${item.dept.replace(' ', '')} ${item.course_nbr} (${item.course_name}) w/ ${getProfName(item.prof)}, ${item.sem}`,
        data: displayMode === 'frequency' ? dataPoints : dataPoints.map(val => (val / total) * 100),
        backgroundColor: (aggregateMode || isOnlyOneCourse) ? columnRainbowColors : Rainbow40[Math.round((index * 15) / (filteredData.length - 1) + 0)],
        borderColor: (theme === 'light' ? 'black' : 'white'),
        borderWidth: 0.8,
        minBarLength: 2
      };
    });
    
    // Aggregate Mode Data Return
    if (aggregateMode) {
      const aggregateData = labels.map((_, i) => datasets.reduce((sum, dataset) => sum + dataset.data[i], 0));
      const totalAggregate = aggregateData.reduce((sum, val) => sum + val, 0);
  
      return {
        labels,
        datasets: [{
          label: '',
          data: displayMode === 'frequency' ? aggregateData : aggregateData.map(val => (val / totalAggregate) * 100),
          backgroundColor: columnRainbowColors,
          borderColor: (theme === 'light' ? 'black' : 'white'),
          borderWidth: 0.8
        }]
      };
    }

    // Separate Mode Data Return
    else {
      return {
        labels,
        datasets
      };
    }
  };
  
  const getGraphAspectRatio = () => {
    if (windowWidth < 576) {
      return 1.8;
    }
    if (windowWidth < 768) {
      return 2;
    }
    if (windowWidth < 992) {
      return 2.1;
    }
    return 2.5;
  };

  const graphTextColor = (theme === 'light' ? "#212529" : "#dee2e6");
  const graphGridColor = (theme === 'light' ? "rgba(33, 37, 41, 0.15)" : "rgba(222, 226, 230, 0.15)")

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: displayMode === 'frequency' ? 'Frequency' : 'Percentage',
          color: graphTextColor  // Axis title color
        },
        ticks: {
          color: graphTextColor,  // Tick labels color
          callback: function(value) {
            // y-axis ticks - round to integer
            return displayMode === 'frequency' ? value : `${Math.round(value)}%`;
          }
        },
        grid: {
          color: graphGridColor  // Grid line color
        }
      },
      x: {
        ticks: {
          color: graphTextColor  // X-axis tick labels color
        },
        grid: {
          color: graphGridColor  // X-axis grid line color
        }
      }
    },
    plugins: {
      legend: {
        display: false  // Turn off the legend
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            // tooltip - round to one decimal place
            label += displayMode === 'frequency' ? context.parsed.y : `${context.parsed.y.toFixed(1)}%`;
            return label;
          }
        }
      }
    },
    aspectRatio: getGraphAspectRatio(),
    responsive: true
  }; 

  const graphData = prepareGraphData();
  defaults.font.family = 'Josefin Slab';
  
  const isAggregateDisabled = selectedCourses.length < 2 || selectedCourses.length >= 19;
  const cardTitle = () => {
    if (isOnlyOneCourse) {
      const totalSum = labels.reduce((sum, label) => {
        const jsonLabel = LABELS_TO_JSON_LABELS.hasOwnProperty(label) ? LABELS_TO_JSON_LABELS[label] : label;
        return sum + (theSingleItem[jsonLabel] || 0);
      }, 0);
      
      const gradesText = labels.map((item, index) => {
        let content;
        if (displayMode === "percentage") {
          const itemValue = theSingleItem[(LABELS_TO_JSON_LABELS.hasOwnProperty(item) ? LABELS_TO_JSON_LABELS[item] : item)];
          const percentage = totalSum > 0 ? ((itemValue / totalSum) * 100).toFixed(1) : '0.0';
          content = <>{item}: {percentage}%</>;
        } else {
          content = <>{item}: {theSingleItem[(LABELS_TO_JSON_LABELS.hasOwnProperty(item) ? LABELS_TO_JSON_LABELS[item] : item)]}</>;
        }
    
        const separator = item === "C" ? <br/> : <>{' '}|{' '}</>;
    
        return (
          <span key={index}>
            {content}
            {index !== labels.length - 1 && separator}
          </span>
        );
      });

      return (
        <>
          {theSingleItem.dept.replace(' ', '')} {theSingleItem.course_nbr} (<span style={{ fontVariant: 'small-caps' }}>{toTitleCase(theSingleItem.course_name)}</span>) w/ {getProfName(theSingleItem.prof)}, {theSingleItem.sem}. <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://utdirect.utexas.edu/apps/student/coursedocs/nlogon/?year=${getYearFromSemester(theSingleItem.sem)}&semester=${getSeasonNumFromSemester(theSingleItem.sem)}&department=${theSingleItem.dept.replace(" ", "+")}&course_number=${theSingleItem.course_nbr}&course_title=&unique=&instructor_first=&instructor_last=${getProfName(theSingleItem.prof).split(" ")[0]}&course_type=In+Residence&search=Search`}
            className="emph-on-hover">
              <em>Syllabus</em>
            </a>
            {windowWidth < 1200 ? <br /> : ''}
            <br />
            {gradesText}
        </>
      );
    }
    if (hasCourses) {
      return "Multiple Courses Selected."
    }
    return "";
  }

  return (
    <Card className='shadow mb-2 results-cards the-graph-card'>
      <Card.Body className='d-flex align-items-center justify-content-center'>
        {
          hasCourses && 
            <div style={{ flexGrow: 1 }}>
              <div>
                <p style={{ lineHeight: '110%' }} className="px-1">
                  {cardTitle()}
                </p>         
              </div>
              
              <label className='mx-2'>
                <div style={{verticalAlign: '-0.1em', display: 'inline'}}>
                  <Switch
                    onChange={() => setDisplayMode(displayMode === 'frequency' ? 'percentage' : 'frequency')}
                    checked={displayMode === 'percentage'}
                    height={12}
                    width={30}
                    handleDiameter={8}
                    uncheckedIcon={false}
                    checkedIcon={false}
                  />     
                </div>     
                <span className='mx-2'>Percentage</span>
              </label>

              { saveMode &&
                (<label className='mx-2'>
                  <div style={{verticalAlign: '-0.1em', display: 'inline'}}>
                    <Switch
                      onChange={() => setAggregateMode(!aggregateMode)}
                      checked={aggregateMode}
                      height={12}
                      width={30}
                      handleDiameter={8}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      disabled={isAggregateDisabled}
                    />     
                  </div>     
                  <span className={`ms-2 ${isAggregateDisabled ? 'toggle-label-disabled' : ''}`}>Aggregate</span>
                </label>
                )
              }

              <div style={{ display: 'block' }}>
                <Bar data={graphData} options={options} className="graph-nonempty-content" key={windowWidth} />
              </div>
              
            </div>
        }
        
        {
          !hasCourses &&
          <>
            {/* <div className="graph-empty-content " style={{ display: 'none' }}> */}
              <span className="my-auto" style={{ fontWeight: 'bold' }}>Select a course!</span>
            {/* </div> */}
          </>
        }
        
      </Card.Body>
    </Card>
  );
};

export default GraphCard;

// # Generate rainbow colors
// rainbow_colors <- rainbow(40)

// # Darken the colors
// factor <- 0.8
// darker_rainbow_colors <- adjustcolor(rainbow_colors, red.f=factor, green.f=factor, blue.f=factor)

// # Print the darker colors
// for (color in darker_rainbow_colors) {
//   print(color)
// }
