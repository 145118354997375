// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { A2NewTab } from "./Utilities";

const JEFFERSON_PORTFOLIO = "https://jeffersonyu1.github.io/";
const JEFFERSON_LINKEDIN = "https://www.linkedin.com/in/fuyaoyu/";
const SEC = "https://sites.utexas.edu/sec/";
const SPONSOR = "https://www.paypal.com/donate/?business=W8XQ2L48JH4PC&no_recurring=0&item_name=Help+us+keep+UT+Grades%2B+alive%21&currency_code=USD";
const STEPHANIE_LINKEDIN = "https://www.linkedin.com/in/stephaniewangyh/";
const UTD_GRADES = "https://utdgrades.com/";
const CATALYST = "https://utcatalyst.org/grade-distributions";
const DEREK_VIEWER = "https://derec4.github.io/ut-grade-distribution-viewer/";
const FAVICON_SOURCE = "https://commons.wikimedia.org/wiki/File:Hookem_hand.svg";
const FEEDBACK = "https://docs.google.com/forms/d/e/1FAIpQLServLSVFod4e3yzx7m4KBwZrdEed1lawHgK39p4qyLn6ttcRQ/viewform?usp=sf_link";

const searchHelpText = ( fromSearch ) => {
  return (
  <p className="m-0">
    <ul className="dashed ps-3">
        <li>
          <OverlayTrigger
            key={"right"}
            placement={"right"}
            overlay={
              <Tooltip style={{fontFamily: 'Josefin Slab', lineHeight: '120%'}}>
                <div className='p-2'>
                  Enter dept names without spaces. e.g. Input 'CS' instead of 'C S'.
                </div>
              </Tooltip>
            }
          >
            <span><u>A Department</u>: <b>ECE</b></span>
          </OverlayTrigger>                   
        </li>
        <li>A Course: <b>ECE 360C</b></li>
        <li>A Professor: <b>David Soloveichik</b></li>
        <li>A Professor's Course: <b>ECE 360C Soloveichik</b></li>
        <li>A Specific Semester: <b>ECE 360C Fall 2022</b></li>
        <li>Everything: <b>ECE 360C Soloveichik Fall 2022</b></li>
    </ul>
    {
      fromSearch &&
      <>
        <br />
        Click the "Info" on the top right for more about this site.
      </>
    }
  </p>
  );
}

const InfoModal = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="px-2 pb-1 pt-2">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            UT Grades+
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Acknowledgements</h5>
          <p className="m-0">
            <ul className="dashed ps-3">
                <li>
                  Built by <A2NewTab href={JEFFERSON_LINKEDIN}>Jefferson Yu</A2NewTab>
                </li>
                <li>
                  Designed by <A2NewTab href={STEPHANIE_LINKEDIN}>Stephanie Wang</A2NewTab>
                </li>
                <li>
                  Inspired by <A2NewTab href={CATALYST}>Catalyst</A2NewTab>, <A2NewTab href={DEREK_VIEWER}>Distribution Viewer</A2NewTab>, and <A2NewTab href={UTD_GRADES}>UTD Grades</A2NewTab>
                </li>
                <li>
                  Favicon from <A2NewTab href={FAVICON_SOURCE}>Wikimedia Commons</A2NewTab>
                </li>
                <li>
                  <b><A2NewTab href={FEEDBACK}>Send your feedback and report bugs here.</A2NewTab></b>
                </li>
                <li>
                  <b>Enjoying the site? <A2NewTab href={SPONSOR}>Please help us keep it running!</A2NewTab></b>
                </li>
            </ul>
            <br />
          </p>          
          <h5>What Can I Search?</h5>
          {searchHelpText(false)}
        </Modal.Body>
      </div>
    </Modal>
  );
}

const SearchInfoModal = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="px-2 pb-1 pt-2">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            What Can I Search?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {searchHelpText(true)}
        </Modal.Body>
      </div>
    </Modal>
  );
}

export { InfoModal, SearchInfoModal };
