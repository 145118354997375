import { Card, Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Person, XLg } from 'react-bootstrap-icons';
import { Rainbow40_Light } from './Colors.js'
import { getProfName, toTitleCase } from "./Utilities.ts";

const SaveCard = ({ data, selectedCourses, removeCourse, windowSize, aggregateMode }) => {
  const getNumCols = () => {
    const windowWidth = windowSize[0];
    if (windowWidth < 576) return 1;
    if (windowWidth < 768) return 2;
    if (windowWidth < 992) return 3;
    if (windowWidth < 1400) return 4;
    return 6;
  }

  const sumGrades = (item) => {
    return ['A', 'A_minus', 'A_plus', 'B', 'B_minus', 'B_plus', 'C', 'C_minus', 'C_plus', 'D', 'D_minus', 'D_plus', 'F', 'Other']
      .reduce((acc, grade) => acc + item[grade], 0);
  };

  const getPageData = () => {
    return data;
  };

  const getShortSem = (sem) => {
    const sem_split = sem.split(" ");
    if (sem_split[0] === "Fall") {
      sem_split[0] = "Fall";
    } else if (sem_split[0] === "Spring") {
      sem_split[0] = "Spr";
    } else if (sem_split[0] === "Summer") {
      sem_split[0] = "Sum";
    }
    return sem_split.join(" ");
  }

  /*
    savecard-cell
    savecard-cell-reg
    savecard-cell-striped
    if window is 
  */


  const getCellStyle = (index, numCols) => {
    if (numCols % 2 !== 0) {
      return (index % 2 === 0 ? 'savecard-cell-reg' : 'savecard-cell-striped');
    }

    return (((index % 2 === 0 ? 0 : 1) + (Math.floor(index / numCols) % 2 === 0 ? 0 : 1)) % 2 === 0 ? 'savecard-cell-reg' : 'savecard-cell-striped');
  }

  const getCells = () => {
    const cells = [];
    const numCols = getNumCols();

    const filteredData = getPageData().filter(item => selectedCourses.includes(item.internal_id));
    filteredData.forEach((item, index) => {
      cells.push(
        <Col key={index} sm={6} md={4} lg={3} xxl={2} className={`d-flex align-items-center savecard-cell ${getCellStyle(index, numCols)}`} style={{ padding: '0.6em 0.75rem 0.5rem', backgroundColor: aggregateMode || filteredData.length === 1 ?  "" :  `${Rainbow40_Light[Math.round((index * 15) / (filteredData.length - 1) + 0)]}`}}>
          <OverlayTrigger
            key={"bottom"}
            placement={"bottom"}
            overlay={
              <Tooltip>
                <span style={{ fontVariant: 'small-caps' }}>{toTitleCase(item.course_name)}</span>
              </Tooltip>
            }
          >
            <div style={{ flex: 1, textAlign: 'left', paddingRight: '0.75rem', lineHeight: '100%' }}>
              <span className='fw-bold'>{item.dept}&nbsp;{item.course_nbr}</span> w/&nbsp;
              <span className='text-capitalize'>{getProfName(item.prof)}</span>
              <br />
              <span>
                {getShortSem(item.sem).replace(/ /g, '\u00a0')} - {sumGrades(item)}
                <Person style={{verticalAlign: '-0.186em'}}/>
              </span>
            </div>
          </OverlayTrigger>
          
          
          <XLg
            onClick={() => removeCourse(item.internal_id)}
            style={{ cursor: 'pointer' }}
          />
        </Col>
      );
    });
    return cells;
  };

  return (
    <Card className="shadow">
      <Card.Body>
        <div className="mb-1">
          <span className="mx-2" style={{ float: 'left', fontWeight: 'bold' }}>In Graph:</span>
          <span className="mx-2 emph-on-hover" style={{float: 'right'}} onClick={() => getPageData().forEach(item => {
            removeCourse(item.internal_id);
          })}><u>Remove All</u></span>
        </div>
        
        <Container fluid className="table-grid" style={{ height: '30vh', overflowY: 'auto' }}>
          <Row>
            { getCells() }
          </Row>
        </Container>

        {/* {data.length > entriesPerPage && (
          // Pagination Component Here
        )} */}
      </Card.Body>
    </Card>
  );
};

export default SaveCard;