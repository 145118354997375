import React from 'react';
import { A2NewTab } from "./Utilities"

const JEFFERSON_PORTFOLIO = "https://jeffersonyu1.github.io/";
const JEFFERSON_LINKEDIN = "https://www.linkedin.com/in/fuyaoyu/";
const SEC = "https://sites.utexas.edu/sec/";
const SPONSOR = "https://www.paypal.com/donate/?business=W8XQ2L48JH4PC&no_recurring=0&item_name=Help+us+keep+UT+Grades%2B+alive%21&currency_code=USD";
const STEPHANIE_LINKEDIN = "https://www.linkedin.com/in/stephaniewangyh/";

const SEC_SPONSOR = false;

const Footer = () => {
  return (
    <footer className="footer mt-3 py-3 px-3">
      <div className="container fluid text-center">
        <span className="text-muted" style={{ lineHeight: "1em" }}>
            Built by <A2NewTab href={JEFFERSON_LINKEDIN}>Jefferson Yu</A2NewTab>. Designed by <A2NewTab href={STEPHANIE_LINKEDIN}>Stephanie Wang</A2NewTab>.
            {/* Thanks to all supporters and <a>contributors</a>. */}
            {SEC_SPONSOR && <span> Generously supported by the <A2NewTab href={SEC}>Student Engineering Council</A2NewTab> and other donors.</span>}
            {!SEC_SPONSOR && <span> Help us keep the site up by <A2NewTab href={SPONSOR}>supporting it here!</A2NewTab></span>}
        </span>
      </div>
    </footer>
  );
};

export default Footer;
