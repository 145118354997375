const Rainbow40 = [
    "#CC0000B3",
    "#CC1E00B3",
    "#CC3E00B3",
    "#CC5C00B3",
    "#CC7A00B3",
    "#CC9900B3",
    "#CCB700B3",
    "#C2CC00B3",
    "#A3CC00B3",
    "#85CC00B3",
    "#66CC00B3",
    "#47CC00B3",
    "#29CC00B3",
    "#0ACC00B3",
    "#00CC14B3",
    "#00CC33B3",
    "#00CC52B3",
    "#00CC70B3",
    "#00CC8EB3",
    "#00CCAEB3",
    "#00CCCCB3",
    "#00AECCB3",
    "#008FCCB3",
    "#0070CCB3",
    "#0052CCB3",
    "#0033CCB3",
    "#0015CCB3",
    "#0A00CCB3",
    "#2900CCB3",
    "#4700CCB3",
    "#6600CCB3",
    "#8500CCB3",
    "#A300CCB3",
    "#C200CCB3",
    "#CC00B8B3",
    "#CC0099B3",
    "#CC007AB3",
    "#CC005CB3",
    "#CC003EB3",
    "#CC001EB3"
]

const Rainbow40_Light = [
    "#CC000099",
    "#CC1E0099",
    "#CC3E0099",
    "#CC5C0099",
    "#CC7A0099",
    "#CC990099",
    "#CCB70099",
    "#C2CC0099",
    "#A3CC0099",
    "#85CC0099",
    "#66CC0099",
    "#47CC0099",
    "#29CC0099",
    "#0ACC0099",
    "#00CC1499",
    "#00CC3399",
    "#00CC5299",
    "#00CC7099",
    "#00CC8E99",
    "#00CCAE99",
    "#00CCCC99",
    "#00AECC99",
    "#008FCC99",
    "#0070CC99",
    "#0052CC99",
    "#0033CC99",
    "#0015CC99",
    "#0A00CC99",
    "#2900CC99",
    "#4700CC99",
    "#6600CC99",
    "#8500CC99",
    "#A300CC99",
    "#C200CC99",
    "#CC00B899",
    "#CC009999",
    "#CC007A99",
    "#CC005C99",
    "#CC003E99",
    "#CC001E99"
]

export { Rainbow40, Rainbow40_Light }
