import React, { useState, useLayoutEffect, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import TableCard from './TableCard';
import GraphCard from './GraphCard';
import SaveCard from './SaveCard';
import { useStateParams } from './Utilities.ts';
// import useUrlState from '@ahooksjs/use-url-state';

// Merge helper method (remove duplicates)
const merge = (a, b, predicate = (a, b) => a === b) => {
  const c = [...a]; // copy to avoid side effects
  // add all items from B to copy C if they're not already present
  b.forEach((bItem) => (c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem)))
  return c;
}

const Results = ({ data, theme, searchQuery, persistedCourseData, setPersistedCourseData, setPersistedCourseList }) => {
  // const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useStateParams(
    [],
    'courses',
    (val) => (val.join(",")),
    (state) => (state.split(",").map((item) => parseInt(item)).filter((item) => !Number.isNaN(item)))
  );

  // const [displayMode, setDisplayMode] = useState('frequency'); // 'frequency' or 'percentage'
  // const [aggregateMode, setAggregateMode] = useState(false); // 'separate' = false or 'aggregate' = true
  // const [saveMode, setSaveMode] = useState(false); // false = don't save checked across searches, true = save

  const [displayMode, setDisplayMode] = useStateParams(                 // displayMode: 'frequency' or 'percentage'
    'frequency',
    'disp',
    (val) => (val.toString()),
    (state) => (state === 'percentage' ? 'percentage' : 'frequency')
  ); 
  const [aggregateMode, setAggregateMode] = useStateParams(             // aggregateMode: false (separate) or true (aggregate)
    false,
    'agg',
    (val) => (val ? 'true' : 'false'),
    (state) => (state === 'true')
  );
  const [saveMode, setSaveMode] = useStateParams(                       // saveMode (multi-select): false (single) or true (multi)
    false,
    'multi',
    (val) => (val ? 'true' : 'false'),
    (state) => (state === 'true')
  );

  useEffect(() => {
    if (searchQuery && searchQuery !== "") {
      // TODO: do something with the course lists
      if (!saveMode) {
        setSelectedCourses([]);
        setPersistedCourseList([]);
      }
      if (saveMode) {
        setPersistedCourseList(selectedCourses);
      }
    }
  }, [searchQuery]);

  const toggleCourseSelection = (courseId) => {
    if (saveMode) {
      setSelectedCourses((prevSelected) => {
        const newSelectedCourses = prevSelected.includes(courseId)
          ? prevSelected.filter(id => id !== courseId)
          : [...prevSelected, courseId];
        setModesFromCourseSelection(prevSelected, newSelectedCourses);
        return newSelectedCourses;
      });
  
      setPersistedCourseData((prevData) => {
        const newData = prevData.some(item => item.internal_id === courseId)
          ? prevData.filter(item => item.internal_id !== courseId)
          : [...prevData, data.find(item => item.internal_id === courseId)]
        return newData;
      });
    } else {
      setSelectedCourses((prevSelected) => {
        const newSelectedCourses = [courseId];
        setModesFromCourseSelection(prevSelected, newSelectedCourses);
        return newSelectedCourses;
      });
  
      setPersistedCourseData(() => {
        return [data.find(item => item.internal_id === courseId)];
      });
    }
  };

  const addCourseToSelection = (courseId) => {
    setSelectedCourses((prevSelected) => {
      const newSelectedCourses = prevSelected.includes(courseId)
          ? prevSelected
          : [...prevSelected, courseId];
      setModesFromCourseSelection(prevSelected, newSelectedCourses);
      return newSelectedCourses;
    });
    setPersistedCourseData((prevData) => {
      const newData = prevData.some(item => item.internal_id === courseId)
        ? prevData
        : [...prevData, data.find(item => item.internal_id === courseId)]
      return newData;
    });
  };

  const removeCourseFromSelection = (courseId) => {
    setSelectedCourses((prevSelected) => {
      const newSelectedCourses = prevSelected.filter(id => id !== courseId);
      setModesFromCourseSelection(prevSelected, newSelectedCourses);
      return newSelectedCourses;
    });
    setPersistedCourseData((prevData) => {
      const newData = prevData.filter(item => item.internal_id !== courseId)
      return newData;
    });
  }

  const setModesFromCourseSelection = (prevSelected, currSelected) => {
    if (currSelected.length === 1) {
      setAggregateMode(false);
    }

    if (prevSelected.length <= 5 && currSelected.length >= 6) {
      setAggregateMode(true);
    }

    if (prevSelected.length <= 18 && currSelected.length >= 19) {
      setAggregateMode(true);
    }

    if (prevSelected.length < 2 && currSelected.length >= 2) {
      setDisplayMode('percentage');
    }
    
    if (prevSelected.length >= 2 && currSelected.length < 2) {
      setDisplayMode('frequency');
    }
  }

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  const mergeOutput = merge(persistedCourseData, data, (a, b) => a.internal_id === b.internal_id);
  const windowSize = useWindowSize();

  return (
    <Row className="mt-4">
      <Col md={5} lg={4} xl={3} className={`${windowSize[0] < 768 ? '' : 'pe-0'}`}>
        <TableCard
          data={data}
          selectedCourses={selectedCourses}
          toggleCourseSelection={toggleCourseSelection}
          addCourse={addCourseToSelection}
          removeCourse={removeCourseFromSelection}
          className={"results-table"}
          windowSize={windowSize}
          saveMode={saveMode}
          setSaveMode={setSaveMode}
        />
      </Col>
      <Col md={7} lg={8} xl={9}>
        <GraphCard 
            data={mergeOutput} 
            selectedCourses={selectedCourses} 
            displayMode={displayMode}
            aggregateMode={aggregateMode}
            saveMode={saveMode}
            setDisplayMode={setDisplayMode}
            setAggregateMode={setAggregateMode}
            className={"the-graph"}
            windowSize={windowSize}
            theme={theme}
          />
      </Col>
      <Col>
        {saveMode &&
          <SaveCard
              data={mergeOutput}
              selectedCourses={selectedCourses}
              removeCourse={removeCourseFromSelection}
              aggregateMode={aggregateMode}
              windowSize={windowSize}
              className={"save-table"}
            />
        }
      </Col>
    </Row>
  );
};

export default Results;