import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';

import './App.css';
import SearchBar from './components/SearchBar';
import UTGradesNavbar from './components/UTGradesNavbar';
import Results from './components/Results';
import Footer from './components/Footer';
import FontFaceObserver from 'fontfaceobserver';
import useLocalStorage from "./hooks/useLocalStorage";
import { useStateParams, clearStateParams } from './components/Utilities.ts';
// import { HandThumbsDown } from 'react-bootstrap-icons';
// import { useUpdateQueryParam } from './components/Utilities.ts';

let updateTimeout;
let lastSearchQuery = "";

const apiURL = process.env.REACT_APP_API_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;
const isMaintenanceMode = (process.env.REACT_APP_MAINTENANCE_MODE === 'maintenance');

function App() {
  const [searchQuery, setSearchQuery] = useStateParams(
    null,
    'query',
    (val) => (val ? val.toString() : ""),
    (state) => (state === "" ? null : state.toString())
  );

  const [searchResults, setSearchResults] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasResults, setHasResults] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [searchError, setSearchError] = useState(null);

  const [persistedCourseData, setPersistedCourseData] = useState([]);

  const [persistedCourseList, setPersistedCourseList] = useStateParams(
    [],
    'persist',
    (val) => (val.join(",")),
    (state) => (state.split(",").map((item) => parseInt(item)).filter((item) => !Number.isNaN(item)))
  );

  // const navigate = useNavigate();
  // const location = useLocation();

  // // Update state from URL
  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //   const query = searchParams.get('query');

  //   if (query && query !== searchQuery) handleSearch(query);
  // }, [location.search]);

  // const updateQueryParam = useUpdateQueryParam();

  // // Update URL when state changes
  // useEffect(() => {
  //   if (searchQuery) updateQueryParam('query', searchQuery);
  // }, [searchQuery, navigate]); // , selectedCourses, displayMode, aggregateMode, 

  function delay(ms) {
    return new Promise((resolve) => {
       setTimeout(resolve, ms);
    })
  }

  useEffect(() => {
    if (searchQuery && searchQuery !== "") {
      if (searchQuery !== lastSearchQuery) {
        if (updateTimeout) {
          clearTimeout(updateTimeout);
        }
        updateTimeout = setTimeout(() => {
          lastSearchQuery = searchQuery;
          handleSearch(searchQuery);
        }, 300); // Adjust the timeout duration as needed
  
        return () => clearTimeout(updateTimeout);
      }
    } else {
      lastSearchQuery = "";
      setSearchQuery(null);
      clearStateParams();
      setHasResults(false);
    }
  }, [searchQuery, persistedCourseList]);

  // const handleSearch = (query, persist=[]) => {
  //   if (!query) return;
  //   if (searchQuery == null) {
  //     setIsLoading(true);
  //   }

  //   setSearchQuery(query);
  //   console.log(`Searching for: ${query}`);    
  
  //   fetch(`/search?q=${query}`)
  //     .then(async res => {
  //       await delay(200);
  //       setIsLoading(false);
  //       if (!res.ok) {
  //         // If response is not ok, throw an error
  //         const data = await res.json();
  //         throw new Error(data.message || 'Error fetching data');
  //       }
  //       return res.json();
  //     })
  //     .then(data => {
  //       console.log(data);        
  //       setSearchResults(data);
  //       setHasResults(true);
  //       setSearchError(null);
  //     })
  //     .catch((error) => {        
  //       setTimeout(setIsLoading(false), 5000);
  //       setSearchError(error.message);
  //     });
  // };

  const handleSearch = (query) => {
    if (!query) return;
    if (searchQuery == null) {
      setIsLoading(true);
    }

    const headers = {
      'x-api-key': apiKey
    };

    setSearchQuery(query);
    // console.log(`Searching for: ${query}`);

    const fetchSearch = fetch(`${apiURL}/api/search?q=${query}`, { headers });
    // console.log(persistedCourseList);
    const fetchPersist = persistedCourseList.length > 0 && persistedCourseList.some((course) => !persistedCourseData.some((item) => item.internal_id === course))
      ? fetch(`${apiURL}/api/persist-search?q=${persistedCourseList.join(',')}`, { headers })
      : Promise.resolve(null); // Resolve to null if persist array is empty

    Promise.all([fetchSearch, fetchPersist])
      .then(async ([searchRes, persistRes]) => {
        await delay(200);

        if (!searchRes.ok) {
          const data = await searchRes.json();
          throw new Error(data.message || 'Error fetching search data');
        }

        const searchData = await searchRes.json();
        let persistData = [];

        if (persistRes && !persistRes.ok) {
          const data = await persistRes.json();
          throw new Error(data.message || 'Error fetching persist data');
        } else if (persistRes) {
          persistData = await persistRes.json();
          
          // console.log(persistData);
          setPersistedCourseData(persistData);
        }

        setIsLoading(false);
        setSearchResults(searchData);
        setHasResults(true);
        setSearchError(null);

        // Handle persist data here if needed
        // setPersistData(persistData); // Assuming you have a state to store persist data

        // console.log(searchData, persistData);
      })
      .catch((error) => {        
        setTimeout(setIsLoading(false), 5000);
        setSearchError(error.message);
      });
  };

  const [theme, setTheme] = useLocalStorage('theme_toggle', 'light'); // default theme

  useEffect(() => {
      document.documentElement.setAttribute('data-bs-theme', theme);
  }, [theme]);

  const toggleTheme = () => {
      setTheme(theme === 'light' ? 'dark' : 'light');
  };

  
  useEffect(() => {
    const fontObserver = new FontFaceObserver('Josefin Slab'); // Replace with your font name
    const fontLoaded = fontObserver.load();

    const contentLoaded = new Promise((resolve) => {
      if (document.readyState === 'complete') {
        resolve();
      } else {
        window.addEventListener('load', resolve);
      }
    });

    Promise.all([fontLoaded, contentLoaded]).then(() => {
      setIsLoading(false)
    }).catch(() => {
      // console.log("Font loading or content loading failed, displaying content with default settings");
      setIsLoading(false)
    });
  }, []);

  useEffect(() => {
    const updateHeaderHeight = () => {
      const header = document.querySelector('.App-header');
      if (header) {
        setHeaderHeight(header.offsetHeight);
      }
    };
  
    // Update footer height on mount and on window resize
    window.addEventListener('resize', updateHeaderHeight);
    updateHeaderHeight(); // Initial update
  
    // Clean up
    return () => {
      window.removeEventListener('resize', updateHeaderHeight);
    };
  }, []);

  return (
    <div className={"App"}>
      <div className={`${isLoading ? 'zero-opacity' : 'full-opacity'}`}>
        <header className="App-header">
          <UTGradesNavbar hasSearchResults={hasResults} theme={theme} toggleTheme={toggleTheme}/>
        </header>

        <div className={`main-content-wrapper ${isLoading ? 'zero-opacity' : 'full-opacity'}`} style={{ minHeight: `calc(100vh - ${headerHeight}px)` }}>
          <Container fluid className={`px-4 ${hasResults ? 'pt-3' : 'd-flex align-items-center justify-content-center'}`} style={{ flexGrow: 1 }}>
            <div>
              {!hasResults && <h1 className="mb-4 text-uppercase">UT Grades+</h1>}

              {!isMaintenanceMode &&
                <>
                  <SearchBar hasResults={hasResults} onSearch={handleSearch} searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                  {!hasResults && <p className="invisible"><br></br></p>}
                  {searchError && <p>We hit a snag. Try again momentarily.<br />{searchError}</p>}
                  {hasResults && <Results data={searchResults} theme={theme} searchQuery={searchQuery} persistedCourseData={persistedCourseData} setPersistedCourseData={setPersistedCourseData} setPersistedCourseList={setPersistedCourseList} />}
                </>
              }

              {isMaintenanceMode &&
                <p>Undergoing brief maintenance. Check again soon!</p>
              }
            </div>
          </Container>

          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;